import React from 'react';
import useAppContext from 'lib/hooks/useAppContext';
import BrandedLandingPageLayout from 'routes/branded_landing_page/BrandedLandingPageLayout';
import ATKHomePage from 'routes/atk_homepage/ATKHomePage';
import parseUser from 'lib/utils/parseUser.ts';
import utils from '../lib/utils';
import { fetchStrapi } from 'lib/api/strapi';

const AtkHomepageLoggedOut = () => {
  const { url } = useAppContext();
  return (
    <BrandedLandingPageLayout
      canonical={`${url}atk`}
      meta={{
        title:
          "America's Test Kitchen: Recipes & Reviews You Can Trust | Stream Every Episode | America’s Test Kitchen",
        description:
          'Learn our unique approach to crafting the best recipes, plus unbiased product reviews, time-saving tips & clever hacks from our test cooks and kitchen experts.',
      }}
    >
      <ATKHomePage />
    </BrandedLandingPageLayout>
  );
};

AtkHomepageLoggedOut.getInitialProps = async (context) => {
  const { req, res, query } = context;
  const { reducedSegment } = parseUser(req);
  const url = utils.requestToUrl(req);
  const subdomain = url.match(/:\/\/([a-z-23]*)/)?.[1] ?? 'www';

  if (res && reducedSegment === 'multisite') {
    const redirect = await getRedirectPath({ query });

    const urlRedirection =
      subdomain === 'localhost'
        ? `${redirect}`
        : `https://${subdomain}.americastestkitchen.com${redirect}`;

    // Server-side
    res.writeHead(302, {
      Location: urlRedirection,
    });
    res.end();
    return {};
  }

  return {};
};

export default AtkHomepageLoggedOut;

async function getRedirectPath({ query }) {
  const queryString = Boolean(Object.keys(query).length)
    ? `?${new URLSearchParams(query).toString()}`
    : '';

  const strapiHeaderData = await fetchStrapi('/api/headers/current', {
    populate: ['holidayLink'],
  });

  const href = strapiHeaderData?.data?.attributes?.holidayLink?.href;

  if (!href) {
    return `/browse${queryString}`;
  }

  // just in case query params are added to header
  const pathname = href.split('?')[0];

  return `${pathname}${queryString}`;
}
